.restaurantImage {
    display: block;
    height: 500px;    
    margin-top: 25px;
    width: 100%;
    /* object-fit: contain; */
}

.foodDeliveryDetails {
    font-size: 15px;
}

.resturantTitle {
    font-family: "bilo",sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
}

.row {
    display: block;
    margin-top: 10px;
}

.tag {
    display: block;
    margin-bottom: 25px;
    width: 100px;
    font-size: 14px;
}


