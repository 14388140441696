div.scrollmenu {
    background-color: red;
    overflow-x: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu a:hover {
    background-color: #777;
}

/* div {
    border: 1px solid black;
    overflow-x: scroll;
} */