@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
}

#navbar {
  text-align: center;
  list-style-type: none;
}

#navbar li {
  display: inline;
  padding: 8px;
  background-color: #dddddd;
}

#map {
  height: 100vh;
  position: fixed;
  float: left;
  right: 0;
  width: 100%;
  padding: 10px;
}

table, th, td {
  border: 1px solid black;
}

/**** Article links ******/

.article {
  height: 300px;
  width: 300px;
  display: block;
  object-fit: contain;
}

.articleImage {
  display: block;
  height: 300px;
  margin-top: 25px;
  width: 100%;
  /* object-fit: contain; */
}

.articlePreview {
  /* width: 90%; */
  /* margin-left: 5vw;
  margin-right: 5vw; */
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: Paralucent;
}

.articlePreview2 {
  width: 100%;    
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-top: solid red;
  border-width: 1px;
  font-family: Paralucent;
}

.articleTitle { 
  font-size: 20px;
  font-weight: 900;  
}

/**** Article links ******/

/* Nav Bar ****************/

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}

.container {
  width: 100%;
	margin-right: auto;
	margin-left: auto
}

img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}

ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}

.menu-item {
  line-height: 23px;
}

li {
  list-style-type: none;
}

.menu-item a {
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #07a8ff !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}

@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}

/************ Nav Bar ***********/
 
span {
  padding: 10px;
}

a {
  /* text-align: center; */
  display:block;
  color: black;
}

/******** Icons ************/
/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 20%;
}

.container {
  grid-template-columns: repeat(2, 1fr);
}

/* split */

@media (min-width: 900px) {
  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that floats next to each other */  
  .column-main {
    float: left;
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .column-main-right {    
    float: left;    
    position: fixed;
    right: 0;
    width: 50%;

  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media only screen and (max-width: 900px) {
  .column-main {
    width: 100%;
    padding: 0px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* * {
    box-sizing: content-box;
  }  */
}

/* split */

.articleContainer {
  margin: 50px;  
}

.tag {  
  background: #ececec;
  border-style: solid;  
  border-width: 1px;
  display: inline;
  padding: 5px;
  text-align: center;
}

.focused {
  margin: 5px;
  padding: 10px;
  border-style: solid;
  border-color: red;
}

/***** Stripe Checkout form *******/

#root {
  display: flex;
  margin: auto;
} 

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  /* This makes it responsive */
  /* justify-content: center; */
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.purchaseButton {
  color: white;
  width: 80%;
  background-color: red;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Buttons and links */
button {
  background: black;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
} */

/* button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

/* Side Bar */

html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  /* left: 36px; */
  top: 36px;
  right: 0%;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Sidebar */

.icon {
  height: 50px;
  width: 50px;
}

.grid-container-essentials {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  
  padding: 10px;
}

.grid-container-essentials > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
}

.grid-container-area {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 3px;
  /* background-color: #2196F3; */
  padding: 5px;
}

.grid-container-area > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 15px;
}

.cityTitle {
  font-family: "Copperplate";
  text-align: center;
  text-decoration: none;
  font-size: 75px;
}

/* .item1 {
  grid-row: 1 / span 2;
} */

#loginButton {
  margin-top: 20px;
  margin-bottom: 20px;
  margin: auto;
  width: 60%;
}

.sellingImage {
  margin-left: 5vw;
  margin-right: 5vw;
  width: 90%;  
}

.description {
  padding: 10px;  
  font-size: 3vw;
  text-align: center;  
}

.ticketTitle {
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 8vw;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.helloUser {  
  font-size: 3vw;
  text-align: center;
}

.orderForm {
  display:flex;
  justify-content: center;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=email], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: red;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: red;
}

.cityName {
  background: white;
  color: black;
}

a {
  text-decoration: none;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

/* Modal */

.submitButton { 
  background-color: red;
  width: 80%;
  margin: auto;
}

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}